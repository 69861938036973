import { useState } from 'react'
import { Formik } from 'formik'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { inviteCandidates } from '../../Store/assessmentSlice'
import { bulkInviteCandidates } from '../../Store/assessmentSlice'
import { t } from 'i18next'
import { BsWhatsapp } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { MdOutlineEmail } from 'react-icons/md'
import { formatedDate } from '../../Utils/Index'
import { LiaMailBulkSolid } from 'react-icons/lia'
import { MdFileDownload } from 'react-icons/md'

function SendMail({ isOpen, setIsOpen, data }) {
  const dispatch = useDispatch()
  const toggle = () => {
    setIsOpen(!isOpen)
    setFileName('')
    if(isOpen === true){
      setIsShareViaMail(false)
      setIsBulkShare(false)
    }
  }
  const [isShareviaMail, setIsShareViaMail] = useState(false)
  const [isBulkShare, setIsBulkShare] = useState(false)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [fileName, setFileName] = useState('')

  const downloadCsvTemplate = () => {
    // process.env.PUBLIC_URL +
    const templateUrl = '/Template.csv'

    // Create an anchor element
    const anchor = document.createElement('a')
    anchor.href = templateUrl
    anchor.download = 'Template.csv'
    anchor.click()
  }
  // const expirationTime = 12 // in hours
  // const expirationMessage = `Please note that the assessment link will expire in ${expirationTime} hours.`
  let msg = `Hi,

We hope this message finds you well. You have been invited to take the assessment! 🚀
  
**Assessment Details:**
  📝 Assessment Name: ${data?.assessmentName} 
  📅 Date: ${formatedDate(new Date())}
  🕒 Time: ${data?.assessment_duration} min
  🔗 Assessment Link: ${data?.assessment_invite_url}
  
**Instructions:**
  1. Click on the provided link to access the assessment.
  2. Ensure a stable internet connection.
  3. Complete the assessment within the specified time.
  
We appreciate your time and effort in taking this assessment. Good luck! 🌟`

  return (
    <Modal size="md" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t('inviteCandidates')}</ModalHeader>
      <ModalHeader
        className="text-center cursor-pointer hover:bg-slate-200"
        onClick={() => setIsShareViaMail(!isShareviaMail)}
      >
        <div className="flex justify-start items-center text-center w-full ">
          <div className="rounded-full bg-primary p-2">
            <MdOutlineEmail className="text-xl text-light" />
          </div>
          <div className="text-center ml-4 text-sm">Share via Email</div>
        </div>
      </ModalHeader>
      {isShareviaMail && (
        <ModalBody>
          <Formik
            initialValues={{ candidateName: '', candidateEmail: '' }}
            validate={(values) => {
              const errors = {}
              if (!values.candidateName) {
                errors.candidateName = t('required')
              }
              if (!/^[A-Z]/.test(values.candidateName)) {
                errors.candidateName = t('FirstLetterCapital')
              }
              if (!values.candidateEmail) {
                errors.candidateEmail = t('required')
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.candidateEmail
                )
              ) {
                errors.candidateEmail = t('invalidEmailAddress')
              }
              return errors
            }}
            onSubmit={ async (values, { setSubmitting }) => {
              setSubmitting(true)
              let obj = {
                candidateName: values.candidateName,
                candidateEmail: values.candidateEmail,
                assessment_url: data.assessment_url,
                assessment_id: data._id,
              }
              try {
                await dispatch(inviteCandidates(obj)); // Wait for the dispatch to complete
                toggle(); // Close the modal
              } catch (error) {
                return
              } finally {
                setSubmitting(false); // Stop the spinner
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <>
                <form onSubmit={handleSubmit} className="bg-white text-black">
                  <div className="flex justify-between">
                    <div className="">
                      <label
                        htmlFor="candidateName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t('candidateName')}
                      </label>
                      <input
                        type="text"
                        data-testid="name-input"
                        id="candidateName"
                        placeholder={t('enterName')}
                        value={values.candidateName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`mt-1 block p-3 border h-11 ${
                          errors.candidateName && touched.candidateName
                            ? 'border-red-500'
                            : 'border-gray-300'
                        } rounded-md`}
                      />
                      {errors.candidateName && touched.candidateName && (
                        <div className="text-red-500 mt-2 text-sm">
                          {errors.candidateName}
                        </div>
                      )}
                    </div>
                    <div className="">
                      <label
                        htmlFor="candidateEmail"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t('candidateEmail')}
                      </label>
                      <input
                        type="email"
                        id="candidateEmail"
                        placeholder={t('enterEmailp')}
                        value={values.candidateEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`mt-1 block w-full p-3 border h-11 ${
                          errors.candidateEmail && touched.candidateEmail
                            ? 'border-red-500'
                            : 'border-gray-300'
                        } rounded-md`}
                      />
                      {errors.candidateEmail && touched.candidateEmail && (
                        <div className=" text-red-500 mt-2 text-sm">
                          {errors.candidateEmail}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex justify-end w-100 mt-4">
                    {/* <button
                      className="bg-primary rounded px-3 py-2 text-white text-sm"
                      type="submit"
                      disabled
                    >
                      <svg
                        className="animate-spin h-5 w-5 mr-3"
                        viewBox="0 0 24 24"
                      ></svg>
                      {t('submit')}
                    </button> */}

                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="bg-primary rounded px-3 py-2 text-white text-sm text-center"
                    >
                      {isSubmitting ? (
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline w-5 h-5  text-white animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      ) : (
                        t('submit')
                      )}

                      {/* Loading... */}
                    </button>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </ModalBody>
      )}

      <Link
        className="no-underline text-dark hover:bg-slate-200"
        target="_blank"
        to={`https://api.whatsapp.com/send?text=${encodeURIComponent(msg)}`}
      >
        <ModalHeader>
          <div className="flex justify-start items-center text-center w-full">
            <div className="rounded-full bg-success p-2">
              <BsWhatsapp className="text-xl text-light" />
            </div>
            <div className="text-center ml-4 text-sm">Share via whatsapp</div>
          </div>
        </ModalHeader>
      </Link>

      <ModalHeader
        className="text-center cursor-pointer hover:bg-slate-200"
        onClick={() => setIsBulkShare(!isBulkShare)}
      >
        <div className="flex justify-start items-center text-center w-full ">
          <div className="rounded-full bg-teal-400 p-2">
            <LiaMailBulkSolid className="text-xl text-light" />
          </div>
          <div className="text-center ml-4 text-sm">Bulk Invite</div>
        </div>
      </ModalHeader>
      {isBulkShare && (
        <ModalBody>
          <p className="font-bold">General Instructions:</p>
          {/* <hr className='w-12'></hr> */}
          <ul>
            <li className="list-disc">Ensure to Upload Only CSV File.</li>
            <li className="list-disc">
              The CSV file must contain columns named <b>FullName</b> and{' '}
              <b>Email</b>.
            </li>
            <li className="list-disc">
              Download the provided CSV template, fill the details, save and
              then upload it using the upload button.
            </li>
          </ul>

          <Formik
            initialValues={{ file: null }}
            validate={(values) => {
              const errors = {}
              if (!values.file) {
                errors.file = t('required')
              }
              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(false)

              // Create a FormData object and append necessary fields
              const formData = new FormData()
              formData.append('assessment_id', data._id)
              formData.append('candidates', values.file)

              // Debugging: Log form data entries
              // for (let [key, value] of formData.entries()) {
              //   console.log(key, value);
              // }

              // Dispatch your action with the form data
              dispatch(bulkInviteCandidates(formData))

              toggle()
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} className="bg-white text-black">
                <div className="flex justify-between items-center ">
                  <div className="flex">
                    <button
                      type="button"
                      className="flex items-center h-9 bg-success rounded text-sm px-2 py-2 mb-2 text-white "
                      onClick={downloadCsvTemplate}
                    >
                      Download Template
                      <span className="mt-1">
                        {' '}
                        <MdFileDownload />
                      </span>
                    </button>
                  </div>
                  <div className="flex">
                    <div className="flex flex-col ml-1 h-12  items-center  ">
                      <label
                        htmlFor="uploadFile1"
                        className="flex  align-center ml-1 bg-gray-700 hover:bg-gray-700 text-white text-sm px-2 py-2 outline-none rounded w-max cursor-pointer  font-[sans-serif]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 mr-2 fill-white inline"
                          viewBox="0 0 32 32"
                        >
                          <path
                            d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                            data-original="#000000"
                          />
                          <path
                            d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                            data-original="#000000"
                          />
                        </svg>
                        Upload
                        <input
                          type="file"
                          id="uploadFile1"
                          className="hidden"
                          accept=".csv"
                          onChange={(event) => {
                            const file = event.currentTarget.files[0]
                            if (file) {
                              setFieldValue('file', file)
                              setFileName(file.name)
                            } else {
                              setFieldValue('file', '')
                              setFileName('')
                            }
                          }}
                          onBlur={handleBlur}
                        />
                      </label>
                      {fileName && (
                        <p className="mt-1 text-sm ml-1 text-gray-600">
                          {fileName}
                        </p>
                      )}
                      {errors.file && touched.file && (
                        <div className="text-red-500 mt-1 ml-1 text-sm">
                          {errors.file}
                        </div>
                      )}
                    </div>
                    <div className="flex  ml-1  h-9  ">
                      <button
                        className="bg-primary rounded text-sm px-2 py-2 text-white "
                        type="submit"
                      >
                        {t('bulkInvite')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </ModalBody>
      )}
    </Modal>
  )
}

export default SendMail
