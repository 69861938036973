/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { usePageVisibility } from 'react-page-visibility'
import { t } from 'i18next'
import TabChangeWorning from '../Modal/TabChangeWorning.js'
import CodeEditor from '../CodeEditor/CodeEditor.js'
import { submitTest } from '../../Store/testSlice.js'
import { MdOutlineTimer } from 'react-icons/md'
import { parse } from 'node-html-parser'
import Loader from '../Common/Loader.js'
import screenfull from 'screenfull'
import {
  uploadAnticheatAudios,
  uploadAnticheatSnapshots,
} from '../../Store/assessmentSlice.js'
import AlertModal from '../Modal/AlertModal.js'

const CandidateTestScreens = ({
  toggleFullscreen,
  candidateId,
  testInfoData,
  setTestSubmited,
  setIsFullscreen,
  streamMediaInfo,
  setStreamMediaInfo,
}) => {
  const dispatch = useDispatch()
  const isPageVisible = usePageVisibility()
  const [questions, setQuestions] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [userResponses, setUserResponses] = useState([])
  const [countDown, setCountDown] = useState(
    parseInt(testInfoData?.assessment_duration) * 60
  )
  const isNegativeMarking = testInfoData?.isNegativeMarking
  // const [isNegativeMarking, setIsNegativeMarking] = useState(
  //   testInfoData?.isNegativeMarking
  // )
  const [isLoading, setIsLoading] = useState(false)
  const recordedChunksRef = useRef([])
  const scheduledTasksRef = useRef([])
  const reinitIntervalRef = useRef(null)
  const [tabChangeCount, setTabChangeCount] = useState(0)
  const [isFullScreenExited, setIsFullScreenExited] = useState('false')
  const [showTabChangeWarning, setShowTabChangeWarning] = useState(false)
  const [submitClicked, setSubmitClicked] = useState(false)
  const [submissionTriggered, setSubmissionTriggered] = useState(false)
  const [progressWidth, setProgressWidth] = useState(0)
  const [selectedMcq, setSelectedMcq] = useState([])
  const testStart = useSelector((state) => state.test.startTest)
  const [isMediaOff, setIsMediaOff] = useState(false)
  const [warningMsg, setWarningMsg] = useState(null)
  const [isMicrophoneMuted, setIsMicrophoneMuted] = useState(false)
  const [isCameraSwitchedOff, setIsCameraSwitchedOff] = useState(false)
  const [newAudioStream, setNewAudioStream] = useState(null)
  const [newVideoStream, setNewVideoStream] = useState(null)

  useEffect(() => {
    const timer = setInterval(() => {
      setCountDown((prevCount) => {
        if (prevCount > 0) {
          return prevCount - 1
        } else {
          if (!submissionTriggered) {
            setSubmitClicked(true) // Set submitClicked to true when countdown reaches zero
            setSubmissionTriggered(true) // Set submissionTriggered to true to prevent multiple submissions
            setIsLoading(false)
          } else {
            setIsLoading(false)
          }
        }
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [submissionTriggered])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setShowTabChangeWarning(true)
        setTabChangeCount((prevCount) => prevCount + 1)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [isPageVisible])

  //To detect/handle muliple screen
  useEffect(() => {
    const handleBlur = () => {
      setShowTabChangeWarning(true)
      setTabChangeCount((prevCount) => prevCount + 1)
    }
    window.addEventListener('blur', handleBlur)

    return () => {
      window.removeEventListener('blur', handleBlur)
    }
  }, [])

  // useEffect(() => {
  //   if (screenfull.isEnabled) {
  //     screenfull.on('change', () => {
  //       if (!screenfull.isFullscreen) {
  //         setSubmitClicked(true)
  //         setSubmissionTriggered(true);
  //         setIsLoading(false)
  //       }
  //     });
  //   }
  // }, [toggleFullscreen]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullScreenExited(true)
        setSubmitClicked(true)
        setSubmissionTriggered(true)
        setIsLoading(false)
      }
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [toggleFullscreen])

  const minutes = Math.floor(countDown / 60)
  const seconds = countDown % 60
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes

  const handleNext = () => {
    const updatedResponses = [...userResponses]
    const tempquestions = [...questions]
    const currentQuestionObj = tempquestions[currentQuestion]

    // Check if the user has already responded to the current question
    const existingResponse = updatedResponses.find(
      (response) => response.questionId === currentQuestionObj?._id
    )
    if (!existingResponse) {
      // If no response exists, add a default response with an empty string
      const objectToUpdate = {
        questionId: currentQuestionObj?._id,
        response: '', // Default response when user hasn't selected an option
        code: questions[currentQuestion]?.initialCode,
      }

      updatedResponses.push(objectToUpdate)
      setUserResponses(updatedResponses)
    }

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1)
      const equalNumber = 100 / questions?.length
      setProgressWidth((prev) => prev + equalNumber)
    }
  }

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1)
      const equalNumber = 100 / questions?.length
      setProgressWidth((prev) => prev - equalNumber)
    }
  }

  // Helper function to convert base64 to Blob
  const base64ToBlob = (base64, mime) => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: mime })
  }

  // Function to send the audio Blob to the backend
  const handleAudioUpload = async (blob) => {
    const formData = new FormData()
    const fileName = `${candidateId}.webm` // Name audio file with candidateId
    formData.append('audio_clips', blob, fileName)
    formData.append('candidateId', candidateId)
    await dispatch(uploadAnticheatAudios(formData))
  }

  const handleImageUpload = async (file) => {
    const formData = new FormData()
    formData.append('assessment_snapshots', file)
    formData.append('candidateId', candidateId)
    await dispatch(uploadAnticheatSnapshots(formData))
  }

  async function captureImage() {
    try {
      // Get video stream
      if (!submitClicked) {
        const videoStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        })
        setNewVideoStream(videoStream)
        // Create a video element
        const video = document.createElement('video')
        video.srcObject = videoStream
        video.play()

        // Wait for the video to be ready to play
        await new Promise((resolve) => {
          video.onloadedmetadata = () => resolve()
        })

        // Create a canvas element
        const canvas = document.createElement('canvas')
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight

        // Draw the current frame from the video on the canvas
        const context = canvas.getContext('2d')
        context.drawImage(video, 0, 0, canvas.width, canvas.height)

        // Convert the canvas to a data URL (image)
        const imageDataURL = canvas.toDataURL('image/png')

        if (imageDataURL) {
          // Convert base64 to Blob
          const blob = base64ToBlob(imageDataURL.split(',')[1], 'image/png')
          // Create a new File object and assign a name
          const fileName = `${candidateId}.png`

          const file = new File([blob], fileName, { type: 'image/png' })
          if (file) {
            handleImageUpload(file)
          } else {
            return
          }
        }
      } else {
        return
      }
      // Do something with the imageDataURL, like displaying it or uploading
    } catch (err) {
      setWarningMsg(t('onMediaMsg'))
      setIsMediaOff(true)
      setIsCameraSwitchedOff(true)
    }
  }

  // Function to capture 10 seconds of audio
  const captureAudio = async () => {
    try {
      if (!submitClicked) {
        const liveAudioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        })
        if (liveAudioStream) {
          setNewAudioStream(liveAudioStream)

          // Extract only the audio tracks from the stream
          const audioTracks = liveAudioStream.getAudioTracks()

          // Check if there are audio tracks
          if (audioTracks.length === 0) {
            return
          }

          const audioStream = new MediaStream(audioTracks)
          const mimeType = 'audio/webm; codecs=opus'

          // Check MIME type support
          if (!MediaRecorder.isTypeSupported(mimeType)) {
            // console.error(
            //   `MIME type ${mimeType} is not supported in this browser.`
            // )
            return
          }
          const recorder = new MediaRecorder(audioStream, {
            mimeType: 'audio/webm; codecs=opus',
          })

          // Reset recordedChunksRef before starting a new recording
          recordedChunksRef.current = []

          recorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              recordedChunksRef.current.push(event.data)
            }
          }

          recorder.onstop = () => {
            // Create a new Blob for the 10-second audio capture
            const blob = new Blob(recordedChunksRef.current, {
              type: 'audio/webm',
            })
            // Handle upload or process the audio blob
            if (blob) {
              handleAudioUpload(blob)
            }

            // Reset the chunks for the next recording
            recordedChunksRef.current = []
          }

          // Start recording
          recorder.start()

          // Stop the recording after 10 seconds
          setTimeout(() => {
            recorder.stop()
          }, 11000)
        } else {
          return
        }
      } else {
        return
      }
    } catch (err) {
      setWarningMsg(t('onMediaMsg'))
      setIsMediaOff(true)
      setIsMicrophoneMuted(true)
    }
  }

  const scheduleCaptures = () => {
    const captureInterval = 300000 // 5 minutes in milliseconds

    const imageCapture = setTimeout(() => {
      captureImage()
    }, Math.random() * captureInterval) // Random time within 5 minutes

    const audioCapture = setTimeout(() => {
      captureAudio()
    }, Math.random() * captureInterval) // Random time within 5 minutes
    // Store these in the ref for later cleanup
    scheduledTasksRef.current.push(imageCapture, audioCapture)

    return { imageCapture, audioCapture }
  }

  const clearScheduledTasks = () => {
    scheduledTasksRef.current.forEach((task) => clearTimeout(task))
    scheduledTasksRef.current = [] // Clear the ref array
  }


  const stopMediaTracks = async () => {
    try {
      const stopTracks = (stream) => {
        if (stream && typeof stream.getTracks === 'function') {
          stream.getTracks().forEach((track) => {
            if (track.readyState !== 'ended') {
              track.stop();
            }
          })
        }
      }

      stopTracks(streamMediaInfo)
      stopTracks(newVideoStream)
      stopTracks(newAudioStream)

      setStreamMediaInfo(null)
      setNewVideoStream(null)
      setNewAudioStream(null)
    
    } catch (error) {
      return
    }
  }

  useEffect(() => {
    // This useEffect will be triggered when submitClicked changes
    const submited = async () => {
      if (submitClicked) {
        setIsLoading(true)
        let submitResponse = {
          candidateId: candidateId,
          responses: userResponses,
          tabsChange: tabChangeCount,
          isFullScreenExited: isFullScreenExited,
          isNegativeMarking: isNegativeMarking,
          microphoneMuted: isMicrophoneMuted,
          cameraSwitchedOff: isCameraSwitchedOff,
        }

        // Dispatch submitTest action or any other actions here
        try {
          const response = await dispatch(submitTest(submitResponse))
          if (response?.payload?.status) {
            await stopMediaTracks()
            setIsLoading(false)
            // setSubmitClicked(false)
            setTestSubmited(true)
            screenfull.exit()
            setIsFullscreen(false)
          }
        } catch (err) {
          setIsLoading(false)
          return
        }
        // setIsLoading(false)
        // setSubmitClicked(false)
        // setTestSubmited(true)
        // screenfull.exit()
        // setIsFullscreen(false)
        // Reset submitClicked after the effect runs
      } else {
        setIsLoading(false)
      }
    }
    submited()
  }, [submitClicked])

  const handleSubmit = async () => {
    setIsLoading(true)

    await handleNext()

    setSubmitClicked(true)
  }

  const formik = useFormik({
    initialValues: {
      responses: questions,
    },
    onSubmit: handleSubmit,
  })

  const handleAnswerSelect = (optionObject, selectedOption, e) => {
    const updatedResponses = [...userResponses]
    const tempQuestions = [...questions]
    const questionIndex = tempQuestions.findIndex(
      (question) => question._id === optionObject._id
    )

    if (questionIndex !== -1) {
      const updatedQuestions = [...questions]
      const questionToUpdate = updatedQuestions[questionIndex]

      if (questionToUpdate?.qType === 'MCQ') {
        const { checked, value } = e.target

        // Find the existing response for this question
        let existingResponse = updatedResponses.find(
          (response) => response.questionId === optionObject._id
        )

        let newSelectedMcq = existingResponse ? existingResponse.response : []

        if (checked) {
          newSelectedMcq = [...newSelectedMcq, value]
        } else {
          newSelectedMcq = newSelectedMcq.filter((option) => option !== value)
        }
        // Update the checked status of the options
        setSelectedMcq({
          ...selectedMcq,
          [optionObject._id]: newSelectedMcq,
        })
        const objectToUpdate = {
          questionId: optionObject._id,
          response: newSelectedMcq,
        }

        const indexToUpdate = updatedResponses.findIndex(
          (obj) => obj.questionId === objectToUpdate.questionId
        )

        if (indexToUpdate !== -1) {
          updatedResponses[indexToUpdate] = objectToUpdate
        } else {
          updatedResponses.push(objectToUpdate)
        }

        setUserResponses(updatedResponses)
        updatedQuestions[questionIndex] = questionToUpdate
        setQuestions(updatedQuestions)
      } else {
        questionToUpdate.options.forEach((option) => {
          if (option[selectedOption]) {
            option.checked = true
          } else {
            option.checked = false
          }
        })
        let objectToUpdate = {
          questionId: optionObject._id,
          response: selectedOption,
        }

        const indexToUpdate = updatedResponses.findIndex(
          (obj) => obj.questionId === objectToUpdate.questionId
        )

        if (indexToUpdate !== -1) {
          updatedResponses[indexToUpdate] = objectToUpdate
        } else {
          updatedResponses.push(objectToUpdate)
        }

        setUserResponses(updatedResponses)
        updatedQuestions[questionIndex] = questionToUpdate
        setQuestions(updatedQuestions)
      }
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const updatedResponses = [...userResponses]
    if (testStart && testStart.Questions && testStart.status) {
      const questionsWithCheckedFalse = testStart.Questions.map((question) => {
        let obj = {
          questionId: question?._id,
          response: '',
        }
        updatedResponses.push(obj)
        setUserResponses(updatedResponses)
        if (question?.qType === 'Practical') {
          return {
            ...question,
            options: null, // Set options to null for practical questions
            runCodeResult: null,
          }
        } else {
          return {
            ...question,
            options: question.options
              ? question.options.map((option) => ({
                  ...option,
                  checked: false,
                }))
              : null,
          }
        }
      })

      formik.setFieldValue('responses', questionsWithCheckedFalse)
      setQuestions(questionsWithCheckedFalse)
      setIsLoading(false)
    }
  }, [testStart])

  useEffect(() => {
    if (currentQuestion === 0) {
      setProgressWidth(0)
    }
  }, [progressWidth])

  const handleContextMenu = (e) => {
    e.preventDefault() // Prevent default context menu
  }

  const handleCopyPaste = (e) => {
    e.preventDefault()
  }

  const reinitializeCameraStream = async () => {
    try {
      const videoStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      })
      if (videoStream) {
        setNewVideoStream(videoStream)
        // Function to restart the stream when inactive
        return videoStream
      } else {
        return null
      }
    } catch (error) {
      setWarningMsg(t('onMediaMsg'))
      setIsMediaOff(true)
      setIsCameraSwitchedOff(true)
    }
  }

  const reinitializeAudioStream = async () => {
    try {
      const newStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      })
      if (newStream) {
        setNewAudioStream(newStream)
        // setIsAudio(true)
        return newStream
      } else {
        return null
      }
    } catch (err) {
      setWarningMsg(t('onMediaMsg'))
      setIsMediaOff(true)
      setIsMicrophoneMuted(true)
    }
  }

  useEffect(() => {
    return () => {
      clearScheduledTasks()
      clearInterval(reinitIntervalRef.current)
      stopMediaTracks()
    }
  }, [])

  useEffect(() => {
    if (!submitClicked) {
      const captures = scheduleCaptures()

      const intervalId = setInterval(() => {
        // Schedule next captures
        scheduleCaptures()
      }, 300000) // Reset every 5 minutes

      // Cleanup on unmount
      return () => {
        clearTimeout(captures.imageCapture)
        clearTimeout(captures.audioCapture)
        clearInterval(intervalId)
      }
    }
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!submitClicked) {
        reinitializeCameraStream()
        reinitializeAudioStream()
      }
    }, 7000) // Check every 7 seconds

    return () => clearInterval(intervalId) // Clear interval on component unmount
  }, [isMediaOff])

  useEffect(() => {
    if (!submitClicked) {
      reinitIntervalRef.current = setInterval(() => {
        reinitializeCameraStream()
        reinitializeAudioStream()
      }, 7000) // Check every 7 seconds
    }

    return () => {
      clearInterval(reinitIntervalRef.current)
    }
  }, [submitClicked, isMediaOff])

  useEffect(() => {
    if (submitClicked) {
      clearInterval(reinitIntervalRef.current)
    }
  }, [submitClicked])

  const showAlertModal = useMemo(() => {
    return (
      <AlertModal
        setIsPermissionDenied={setIsMediaOff}
        isPemissionDenied={isMediaOff}
        message={warningMsg}
      />
    )
  }, [isMediaOff])

  if (isLoading) {
    return <Loader />
  }

  return (
    <div
      onContextMenu={handleContextMenu}
      onCopy={handleCopyPaste}
      onPaste={handleCopyPaste}
    >
      {showTabChangeWarning && (
        <TabChangeWorning
          setShowTabChangeWarning={setShowTabChangeWarning}
          showTabChangeWarning={showTabChangeWarning}
        />
      )}
      {showAlertModal}
      <div
        className={`bgc-color  h-screen
          `}
        // ${curretHeight > 100 ? 'h-auto' :
        // 'h-screen'}
      >
        <div className="h-auto bgc-color">
          <nav className="w-full shadow-md shadow-black/5 sticky top-0 z-0 bgc-color">
            <div className="flex justify-between items-center px-5 mt-1">
              <div className="flex items-center">
                <div
                  className=" font-semibold font"
                  data-testid="candidateName"
                >
                  {t('Candidate Name')} :{' '}
                  <span className="font-normal"> {testStart?.candidate}</span>
                </div>
                {/* <FaUserCircle className="cursor-pointer ml-2 text-md font-lg text-dark" /> */}
              </div>
              {testStart?.scoreSetting && (
                <div className=" font-semibold font">
                  {t('Question Mark')} :{' '}
                  <span className="font-normal">
                    {' '}
                    {formik.values.responses[currentQuestion]?.score}
                  </span>
                </div>
              )}
              <div className="flex items-center">
                <div>
                  <MdOutlineTimer />
                </div>
                <div className="text-dark ml-2" data-testid="time">
                  {' '}
                  {t('time')}{' '}
                  {countDown > 0
                    ? `${formattedMinutes}:${formattedSeconds}`
                    : '00:00'}
                </div>
              </div>

              {/* <div>
              <button
                type="button"
                disabled={currentQuestion === 0}  
                className="bg-red-500 text-white text-xs px-3 py-1 rounded-md"
              >
                End Test
              </button>
            </div> */}
            </div>
            <div className="px-5">
              <div className="overflow-hidden h-1 mt-2 text-xs flex rounded bg-gray-200">
                <div
                  style={{ width: `${progressWidth}%` }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
                ></div>
              </div>
              <p className="text-xs text-center py-1">
                {currentQuestion + 1} / {questions?.length}
              </p>
            </div>
          </nav>
          <div>
            {formik.values.responses[currentQuestion]?.qType === 'Practical' ? (
              <CodeEditor
                handleSubmit={handleSubmit}
                handlePrevious={handlePrevious}
                setQuestions={setQuestions}
                currentQuestion={currentQuestion}
                handleNext={handleNext}
                currentCode={questions[currentQuestion]?.initialCode}
                questions={questions}
                userResponses={userResponses}
                setUserResponses={setUserResponses}
                assessment_url={testInfoData?.assessment_url}
              />
            ) : (
              <>
                <div className="p-5">
                  <div className="shadow-md bg-white rounded p-4">
                    <p className="font-semibold text-md lg:text-lg xl:text-lg p-3">
                      {currentQuestion + 1}.{' '}
                      {formik.values.responses[currentQuestion]?.questionTitle}
                      {formik?.values?.responses[currentQuestion]?.qType ===
                        'MCQ' && (
                        <span className="px-2">{`
                      ( Multiple Correct )`}</span>
                      )}
                    </p>
                    {formik?.values?.responses[currentQuestion]
                      ?.questionDescription && (
                      <div className="px-3 border py-2 bg-slate-100">
                        <div
                          id="desc"
                          dangerouslySetInnerHTML={{
                            __html: parse(
                              formik?.values?.responses[currentQuestion]
                                ?.questionDescription
                            ).toString(),
                          }}
                        ></div>
                      </div>
                    )}
                    <form onSubmit={formik.handleSubmit}>
                      <ul className="mt-2 p-0">
                        {formik.values.responses[currentQuestion]?.options?.map(
                          (optionObject, index) => {
                            const key = Object.keys(optionObject)[0]
                            const value = optionObject[key]
                            const id =
                              formik.values.responses[currentQuestion]._id

                            return (
                              <li
                                key={index}
                                className="mb-2 w-full md:w-3/4 lg:w-2/3 xl:w-1/2 border-1 rounded"
                              >
                                <div className="flex items-center ps-3 py-3">
                                  <span className="font-semibold mr-5">
                                    {`${String.fromCharCode(65 + index)}`}:
                                  </span>
                                  {formik?.values?.responses[currentQuestion]
                                    .qType === 'MCQ' ? (
                                    <input
                                      type="checkbox"
                                      id={`default-checkbox-${index}`}
                                      name={`default-checkbox-${index}`}
                                      onBlur={formik.handleBlur}
                                      onChange={(e) =>
                                        handleAnswerSelect(
                                          formik.values.responses[
                                            currentQuestion
                                          ],
                                          key,
                                          e
                                        )
                                      }
                                      checked={selectedMcq[id]?.includes(key)}
                                      // checked={optionObject.checked}
                                      value={key}
                                      className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                      style={{ cursor: 'pointer' }}
                                    />
                                  ) : (
                                    <input
                                      id={`default-radio-${index}`}
                                      type="radio"
                                      onBlur={formik.handleBlur}
                                      onChange={(e) =>
                                        handleAnswerSelect(
                                          formik.values.responses[
                                            currentQuestion
                                          ],
                                          key,
                                          e
                                        )
                                      }
                                      checked={optionObject.checked}
                                      value={key}
                                      name="answer"
                                      className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                      style={{ cursor: 'pointer' }}
                                    />
                                  )}

                                  <label
                                    htmlFor={`default-radio-${index}`}
                                    className="ml-2 text-sm font-medium text-gray-900"
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: parse(value?.toString()),
                                      }}
                                    />
                                  </label>
                                </div>
                              </li>
                            )
                          }
                        )}
                      </ul>
                    </form>
                    <hr />
                    <div className=" flex justify-end ">
                      <button
                        type="button"
                        onClick={handlePrevious}
                        disabled={currentQuestion === 0}
                        className="bg-blue-500 text-white text-xs px-3 py-2 rounded-md mr-2"
                      >
                        {t('previous')}
                      </button>
                      {currentQuestion !== questions.length - 1 && (
                        <button
                          type="button"
                          onClick={handleNext}
                          disabled={currentQuestion === questions.length - 1}
                          className="bg-blue-500 text-white text-xs px-3 py-2 rounded-md mr-2"
                        >
                          {t('next')}
                        </button>
                      )}
                      {currentQuestion === questions.length - 1 && (
                        <button
                          type="submit"
                          onClick={formik.handleSubmit}
                          className="bg-green-500 text-white text-xs px-3 py-2 rounded-md"
                        >
                          {t('submit')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CandidateTestScreens
