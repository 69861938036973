/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, useRef } from 'react'
import CandidateTestScreens from './CandidateTestScreens.js'
import { useDispatch, useSelector } from 'react-redux'
import { getTestInfo, startTest } from '../../Store/testSlice.js'
import TestSubmit from './TestSubmit.js'
import { useLocation } from 'react-router'
import { t } from 'i18next'
import Loader from '../Common/Loader.js'
// import Webcam from 'react-webcam'
import AlertModal from '../Modal/AlertModal.js'

const CandidateTest = () => {
  const startTestData = useSelector((state) => state.test.startTest)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [testInfoData, setTestInfoData] = useState(null)
  const [assessment_url, setAssessment_url] = useState(null)
  const [testSubmited, setTestSubmited] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isPemissionDenied, setIsPermissionDenied] = useState(false)
  const [warningMsg, setWarningMsg] = useState('')
  const [streamMediaInfo, setStreamMediaInfo] = useState(null)
  const [webcamReady, setWebcamReady] = useState(false)

  const dispatch = useDispatch()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')

  const checkMediaPermission = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: { echoCancellation: true },
      })
      .then((stream) => {
        setStreamMediaInfo(stream)
        setWebcamReady(true)
      })
      .catch((error) => {
        if (error.name === t('notAllowed')) {
          setWarningMsg(t('AllowAccess'))
          setIsPermissionDenied(true)
        } else if (error.name === t('notFound')) {
          setWarningMsg(t('notFoundWarning'))
          setIsPermissionDenied(true)
        } else {
          setWarningMsg(error?.message)
          setIsPermissionDenied(true)
        }
      })
  }

  const toggleFullscreen = async () => {
    if (streamMediaInfo) {
      try {
        const element = document.documentElement
        setIsLoading(true)
        if (document.fullscreenEnabled) {
          if (document.fullscreenElement) {
            await document.exitFullscreen()
            setIsFullscreen(false)
            setTestSubmited(false)
          } else {
            const obj = {
              testUrl: assessment_url,
              candidateId: id,
            }

            // Call the startTest API
            const response = await dispatch(startTest(obj))

            // Check if the API call was successful and status is true
            if (response.payload.status) {
              setIsLoading(false)

              await element.requestFullscreen()
              setIsFullscreen(true)
            } else {
              setIsLoading(false)
            }
          }
        } else {
          // console.error('Fullscreen is not supported')
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
      }
    } else {
      checkMediaPermission()
    }
  }

  useEffect(() => {
    if (!testSubmited) {
      checkMediaPermission()
    }
  }, [])

  const testSubmitComponent = useMemo(() => {
    setIsLoading(false)
    return testSubmited && <TestSubmit />
  }, [testSubmited])

  const candidateTestScreensComponent = useMemo(() => {
    return (
      isFullscreen && (
        <CandidateTestScreens
          isFullscreen={isFullscreen}
          toggleFullscreen={toggleFullscreen}
          candidateId={id}
          assessment_url={assessment_url}
          testInfoData={testInfoData}
          setTestSubmited={setTestSubmited}
          setIsFullscreen={setIsFullscreen}
          streamMediaInfo={streamMediaInfo}
          setStreamMediaInfo={setStreamMediaInfo}
          webcamReady={webcamReady}
        />
      )
    )
  }, [isFullscreen, id, assessment_url, testInfoData, setTestSubmited])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)

      try {
        // Fetching startTestData
        if (startTestData && startTestData.status) {
          setIsFullscreen(false)
          setIsLoading(false)
        } else {
          setIsFullscreen(false)
          setIsLoading(false)
        }

        // Fetching testInfo
        const testInfoResponse = await dispatch(
          getTestInfo({ candidateId: id })
        )
        if (
          testInfoResponse &&
          testInfoResponse.payload &&
          testInfoResponse.payload.assesmentDetails
        ) {
          setTestInfoData(testInfoResponse.payload.assesmentDetails)
          setAssessment_url(
            testInfoResponse.payload.assesmentDetails.assessment_url
          )
          setIsLoading(false)
        } else {
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [dispatch, id])

  const handleCopyPaste = (e) => {
    e.preventDefault()
  }

  const showAlertModal = useMemo(() => {
    return (
      <AlertModal
        setIsPermissionDenied={setIsPermissionDenied}
        isPemissionDenied={isPemissionDenied}
        message={warningMsg}
      />
    )
  }, [isPemissionDenied])

  if (isLoading) {
    return <Loader />
  }

  // className="hidden-webcam"
  return (
    <div>
      {!isFullscreen && !testSubmited && (
        <>
          <div
            className="w-full flex items-center justify-center bgc-color min-h-screen px-4"
            onCopy={handleCopyPaste}
            onPaste={handleCopyPaste}
          >
            <div className="w-full lg:w-8/12 xl:w-6/12 border bg-white shadow-md rounded-lg px-6 py-6">
              <div className="font-semibold text-xl lg:text-2xl xl:text-2xl">
                {t('sampleTest')}
              </div>
              <div className="flex flex-col mt-4 text-sm lg:text-xl xl:text-xl">
                <div className="mr-10">
                  {t('testName')}
                  <span className="font-medium">
                    {testInfoData?.assessmentName}
                  </span>
                </div>
                <div className="mr-10 mt-2">
                  {t('testDuration')}
                  <span className="font-medium">
                    {testInfoData?.assessment_duration} {t('min')}
                  </span>
                </div>
              </div>
              <div>
                <div className="text-sm lg:text-xl xl:text-xl font-semibold mt-4">
                  {t('readInstructions')}
                </div>
                <ul className="list-disc mt-3 text-sm lg:text-xl xl:text-xl">
                  <li>{t('usingGoogleChrome')}</li>
                  <li>{t('enableCamera&micro')}</li>
                  <li>{t('dontSwitchOff')}</li>
                  <li>{t('disableAllAdBlockers')}</li>
                  <li>{t('weRecommend')}</li>
                  <li>{t('internetConnection')}</li>
                </ul>
              </div>
              <div className="mt-6 ">
                <button
                  data-testid="start-btn"
                  onClick={toggleFullscreen}
                  className="bg-green-500 text-white px-4 py-2 rounded-lg"
                >
                  {t('startTest')}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {testSubmitComponent}
      {candidateTestScreensComponent}
      {showAlertModal}
    </div>
  )
}

export default CandidateTest
