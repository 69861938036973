import { t } from 'i18next'

const TestSubmit = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bgc-color">
      <div className="shadow-lg rounded-md bg-white py-5 px-5 md:flex md:py-8 md:px-10">
        <div className="flex flex-col items-center text-center space-y-4">
          <img
            src="../../../assets/images/mailSend.png"
            alt="right-img"
            className="w-32 md:w-48"
          />
          <h3 className="text-lg font-semibold md:text-2xl">{t('thankYou')}</h3>
          <p className="text-sm md:text-base text-gray-600">
            {t('testSubmited')}
          </p>
        </div>
      </div>
    </div>
  )
}
export default TestSubmit
